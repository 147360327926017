import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher!: Pusher;
  channel!: any;

  constructor() {
    this.pusher = new Pusher(environment.PUSHER_KEY, {
      cluster: environment.PUSHER_CLUSTER,
      forceTLS: true,
    });
  }

  subscribeToChannel(channelName: string) {
    this.channel = this.pusher.subscribe(channelName);
  }

  subscribeToEvent(event: string, cb: any) {
    this.channel.bind(event, function (data: any) {
      cb(data);
    });
  }

  unsubscribeToChannel(channelName: string): void {
    this.pusher.unsubscribe(channelName);
  }
}
