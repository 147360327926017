import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

import { Subscription, Observable, EMPTY, scheduled, asyncScheduler, throwError } from 'rxjs';
import { catchError, concatAll } from 'rxjs/operators';
import { expand } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth.service';
import { NotifyService } from '@services/notify.service';
import { RestService } from '@services/rest.service';
import { StoreIdbService } from '@services/store-idb.service';
import { PusherService } from '@services/pusher.service';
import moment from 'moment-timezone';
import { User } from './models/user.model';
import { Notify } from './models/notify.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  arrObjectStoreIdb: any = [];
  loading = true;
  rutContributor: string = this.authService.getRutContributors() || '';
  user: User = this.authService.getDataUser();
  listIdx: Array<{ objectStore: string; nameView: string; endpoint: string }> = [
    { objectStore: 'provinces', nameView: 'Datos Provincias', endpoint: 'provinces' },
    { objectStore: 'communes', nameView: 'Datos Comunas', endpoint: 'communes' },
    {
      objectStore: 'economic-activities',
      nameView: 'Datos Actividades Económicas',
      endpoint: 'economic-activities',
    },
  ];
  private _initGetMsg!: Subscription;

  constructor(
    protected authService: AuthService,
    private notifyService: NotifyService,
    private storeIdbService: StoreIdbService,
    private rest: RestService,
    private router: Router,
    private pusher: PusherService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit() {
    this.pusherAdd();

    this._initGetMsg = this.notifyService.dataParent.subscribe(async (data: any) => {
      if (data.active) {
        if (data.action === 'reload_idb') {
          this.storeIdbService.restoreDB();

          setTimeout(() => {
            location.reload();
          }, 1000);
        }

        if (data.action === 'reload_idb_from_login') {
          this.user = this.authService.getDataUser();
          this.rutContributor = this.authService.getRutContributors() || '';

          this.pusherAdd();

          this.listIdx = [
            { objectStore: 'provinces', nameView: 'Datos Provincias', endpoint: 'provinces' },
            { objectStore: 'communes', nameView: 'Datos Comunas', endpoint: 'communes' },
            {
              objectStore: 'economic-activities',
              nameView: 'Datos Actividades Económicas',
              endpoint: 'economic-activities',
            },
          ];

          await this.storeIdbService.initDb();

          setTimeout(() => {
            if (this.authService.getToken()) {
              this.loadObjectIndexedDB();
            }
          }, 1000);
        }

        if (data.action === 'logout') {
          this.pusher.unsubscribeToChannel(`user-${this.user.id}`);
        }
      } else {
        if (this.authService.getToken()) {
          this.loadObjectIndexedDB();
        }
      }
    });
  }

  ngOnDestroy() {
    this._initGetMsg.unsubscribe();
    this.pusher.unsubscribeToChannel(`user-${this.user.id}`);
  }

  pusherAdd() {
    if (this.user.id) {
      this.pusher.subscribeToChannel(`user-${this.user.id}`);

      this.pusher.subscribeToEvent(`contributor-added`, (data: any) => {
        if (typeof data === 'object') {
          let info: Notify = {
            type: 'info',
            title: 'Nueva empresa agregada',
            msg: `La empresa: <strong>${data.name}</strong>, 
                rut: <strong>${data.rut}</strong>, 
                ha sido agregada a su cuenta de usuario
                `,
            date: moment().format('DD/MM HH:mm'),
            btn: false,
            titleBtn: '',
            origin: 'contributor-added',
            data: data,
          };

          this.notifyService.updatedDataForChildrenComponent({ contributorAdded: true, info });
        } else {
          console.error('data inválido', data);
        }
      });

      this.pusher.subscribeToEvent(`turn-production`, (data: any) => {
        if (typeof data === 'object' && data.hasOwnProperty('contributor')) {
          if (data.contributor?.rut === this.rutContributor) {
            this.resetCertificationClient();
          } else {
            let info: Notify = {
              type: 'info',
              title: 'Empresa en producción',
              msg: `La empresa: <strong>${data.contributor.name}</strong>, 
                  rut: <strong>${data.contributor.rut}</strong>, 
                  ha sido cambiada al ambiente producción.
                  `,
              date: moment().format('DD/MM HH:mm'),
              btn: false,
              titleBtn: '',
              origin: 'turn-production',
              data: {},
            };

            this.notifyService.updatedDataForChildrenComponent({ customer: true, info });
          }
        } else {
          console.error('data inválido', data);
        }
      });

      this.pusher.subscribeToEvent('dte-issue-imported', (data: any) => {
        if (typeof data === 'object') {
          let info: Notify = {
            type: 'info',
            title: 'Importación factura de ventas',
            msg: `Empresa: ${data.customer}<br>
                Rut: ${data.rut}<br>
                Total importado <strong>${data.quantity}</strong><br>
                Exitosas <strong class='text-success'>${data.total_processed}</strong><br>
                Erróneas <strong class='text-danger'>${data.total_failed}</strong>`,
            date: moment().format('DD/MM HH:mm'),
            btn: false,
            titleBtn: '',
            origin: 'dte-issue-imported',
            data: {},
          };

          this.notifyService.updatedDataForChildrenComponent({ ventas: true, info });
        } else {
          console.error('data inválido', data);
        }
      });

      this.pusher.subscribeToEvent('dte-receive-imported', (data: any) => {
        if (typeof data === 'object') {
          let info: Notify = {
            type: 'info',
            title: 'Importación factura de compras',
            msg: `Empresa: ${data.customer}<br>
                Rut: ${data.rut}<br>
                Total importado <strong>${data.quantity}</strong><br>
                Exitosas <strong class='text-success'>${data.total_processed}</strong><br>
                Erróneas <strong class='text-danger'>${data.total_failed}</strong>`,
            date: moment().format('DD/MM HH:mm'),
            btn: false,
            titleBtn: '',
            origin: 'dte-receive-imported',
            data: {},
          };

          this.notifyService.updatedDataForChildrenComponent({ compras: true, info });
        } else {
          console.error('data inválido', data);
        }
      });

      this.pusher.subscribeToEvent('download-report-sales', (data: any) => {
        if (typeof data === 'object') {
          let info: Notify = {
            type: 'info',
            title: 'Descarga de reporte de ventas',
            msg: `La empresa: ${data.contributor?.name}, 
                  rut: ${data.contributor?.rut}, 
                  ya tiene disponible su reporte de ventas solicitado.
                  `,
            date: moment().format('DD/MM HH:mm'),
            btn: true,
            titleBtn: 'Descargar Reporte',
            origin: 'download-report-sales',
            data: {
              url: data.url,
            },
          };

          this.notifyService.updatedDataForChildrenComponent({ ventas: true, info });
        } else {
          console.error('data inválido', data);
        }
      });

      this.pusher.subscribeToEvent('download-report-purchases', (data: any) => {
        if (typeof data === 'object') {
          let info: Notify = {
            type: 'info',
            title: 'Descarga de reporte de compras',
            msg: `La empresa: <strong>${data.contributor?.name}</strong>, 
                  rut: <strong>${data.contributor?.rut}</strong>, 
                  ya tiene disponible su reporte de compras solicitado.
                  `,
            date: moment().format('DD/MM HH:mm'),
            btn: true,
            titleBtn: 'Descargar Reporte',
            origin: 'download-report-sales',
            data: {
              url: data.url,
            },
          };

          this.notifyService.updatedDataForChildrenComponent({ ventas: true, info });
        } else {
          console.error('data inválido', data);
        }
      });
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  async loadObjectIndexedDB() {
    for (const element of this.listIdx) {
      let data: any = await this.storeIdbService.getObjectStoreIdb(element.objectStore);
      if (data.length === 0) {
        this.arrObjectStoreIdb.push({
          objectStore: element.objectStore,
          nameView: element.nameView,
          endpoint: element.endpoint,
          status: 'load',
        });
      }
    }

    if (this.arrObjectStoreIdb.length > 0) {
      setTimeout(() => {
        this.setStores();
      }, 1000);
    }
  }

  setStores() {
    const observables: Array<Observable<any>> = [];

    let numErrors: number = 0;

    this.arrObjectStoreIdb.forEach((element: any, index: number) => {
      observables.push(this.getDataBucleFromEndpoint(element.objectStore, element.endpoint, index));
    });

    const sourcesWithCatch = observables.map((s) =>
      s.pipe(
        catchError((e) => {
          throw new TypeError(e);
        })
      )
    );

    scheduled(sourcesWithCatch, asyncScheduler)
      .pipe(concatAll())
      .subscribe({
        next: (data) => {
          if (!Array.isArray(data) || (Array.isArray(data) && data.length === 0)) {
            numErrors += 1;
          }
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Problema detectado al actualizar los datos locales!',
            heightAuto: false,
            text: 'Favor de comunicarse con el administrador',
          });
        },
        complete: async () => {
          if (numErrors === 0) {
            this.notifyService.updatedDataForChildrenComponent({
              active: false,
              data: [],
            });

            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
      });
  }

  getDataBucleFromEndpoint(objectStore: string, endpoint: string, index: number): Observable<any> {
    return new Observable((observer) => {
      let dataArr: any[] = [];
      let pageNumber = 1;

      this.rest
        .index(endpoint, { page: pageNumber, p: 100 })
        .pipe(
          expand((response) => {
            if (response.data.length === 0) {
              return EMPTY;
            } else {
              pageNumber++;
              return this.rest.index(endpoint, { page: pageNumber, p: 100 });
            }
          }),
          catchError((err) => {
            return throwError(() => new Error(err));
          })
        )
        .subscribe({
          next: (data) => {
            if (data.data.length > 0) {
              dataArr = [...dataArr, ...data.data];
            } else {
              if (pageNumber === 1) {
                dataArr = [...dataArr, ...[{ id: 1, value: ' ' }]];
              }
            }
          },
          error: (err) => {
            observer.error(throwError(() => new Error('error')));
          },
          complete: async () => {
            await this.storeIdbService.addRegisterIdb(dataArr, objectStore);

            this.arrObjectStoreIdb[index].status = 'done';

            this.notifyService.updatedDataForChildrenComponent({
              active: true,
              data: this.arrObjectStoreIdb,
            });

            observer.next(dataArr);
            observer.complete();
          },
        });
    });
  }

  resetCertificationClient() {
    let timerInterval: any;

    Swal.fire({
      title: 'Su empresa ha cambiado a producción!',
      html: 'Reiniciando en <b></b> segundos.',
      timer: 4000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
        const b = Swal.getHtmlContainer()?.querySelector('b');
        timerInterval = setInterval(() => {
          b!.textContent = String(Math.round(Number(Swal.getTimerLeft()) / 1000));
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        const arrContributors = this.authService.getContributors();
        arrContributors.map((v: any) => {
          if (v.rut === this.rutContributor) {
            v.certification = false;
          }
          return v;
        });

        const findContributor = arrContributors.find(
          (item: any) => item.rut === this.rutContributor
        );

        setTimeout(() => {
          this.authService.setContributors(arrContributors);
          this.authService.setCurrentContributors(findContributor);
          location.reload();
        }, 100);
      }
    });
  }
}
