import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full' as PathMatch
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'iniciar-sesion',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () =>
      import('./pages/recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'boletas',
    loadChildren: () => import('./pages/boletas/boletas.module').then((m) => m.BoletasModule),
  },
  {
    path: 'paid',
    loadChildren: () => import('./pages/paid/paid.module').then((m) => m.PaidModule),
  },
  {
    path: 'error401',
    loadChildren: () => import('./pages/error401/error401.module').then((m) => m.Error401Module),
  },
  {
    path: 'error403',
    loadChildren: () => import('./pages/error403/error403.module').then((m) => m.Error403Module),
  },
  {
    path: 'error404',
    loadChildren: () => import('./pages/error404/error404.module').then((m) => m.Error404Module),
  },
  { path: '**', redirectTo: '/error404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
