import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  localIp: any;
  publicIp: any;
  arrUrlsZoho: string[]= [
    'https://mail.arisa.cl',
  ];

  constructor(protected authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dataClient: any = this.authService.getCurrentContributor();
    let idBrach: any = this.authService.getIdBranch();

    let positionZoho = request.url.search(new RegExp('https://mail.arisa.cl', 'i'));

    if (
      request.method.toLowerCase() === 'post' ||
      request.method.toLowerCase() === 'put' ||
      request.method.toLowerCase() === 'delete'
    ) {
      if (request.body instanceof FormData) {
        request.body.append('ip', localStorage.getItem('ip') || '');
        request.body.append('ip_public', localStorage.getItem('ip_public') || '');
        request.body.append('certification', dataClient.certification ? '1' : '0');
      } else {
        if (positionZoho < 0) {
          const foo: any = { ip: '', ip_public: '', certification: false };
          foo['ip'] = localStorage.getItem('ip') || '0.0.0.0';
          foo['ip_public'] = localStorage.getItem('ip_public') || '';
          foo['certification'] = dataClient.certification;
          foo['branch_id'] = Number(idBrach);
          request = request.clone({
            body: { ...request.body, ...foo },
          });
        }
      }
    } else {
      let regEx = new RegExp(environment.API_URL.slice(0, -1), 'i');
      let position = request.url.search(regEx);

      if (position >= 0) {
        request = request.clone({
          params: (request.params ? request.params : new HttpParams())
            .set('branch_id', idBrach)
            .set('certification', dataClient.certification ? '1' : '0'),
        });
      } else {
        request = request.clone({
          setHeaders: {},
        });
      }
    }

    return next.handle(request);
  }
}
