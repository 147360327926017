import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs/';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public inj: Injector, private snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthService);

    let regEx = new RegExp(environment.API_URL.slice(0, -1), 'i');
    let position = request.url.search(regEx);

    if (position >= 0) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 403) {
              this.snackBar.open('No dispones de los permisos para realizar esta acción', 'error', {
                duration: 3000,
                verticalPosition: 'top',
              });
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              auth.logout();
            } else if (err.status === 403) {
              this.snackBar.open('No dispones de los permisos para realizar esta acción', 'error', {
                duration: 3000,
                verticalPosition: 'top',
              });
            }
          }
        }
      )
    );
  }
}
